export default [
  {
    key: "thumbnailId",
    initKey: "thumbnail",
    label: "field.thumbnail",
    type: "async-single-image",
    path: "timeline",
    pw: 774,
    ph: 320,
    fullWidth: false,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "year",
    label: "field.year",
    rules: "max:4",
    type: "text",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer|min_value:1",
    type: "currency",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "required|max:500",
    type: "textarea",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "required|max:500",
    type: "textarea",
  },
  // {
  //   key: "isMain",
  //   label: "field.main",
  //   rules: "",
  //   type: "checkbox",
  //   cols: "auto",
  // },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
